// 交易明细管理
import { Request } from '@/http/request'

// 交易明细表
export function tradeList (parameter: any) {
  return Request.axiosInstance.post('/web/trade-detail/list', parameter)
}

// 交易明细表导出
export function tradeExport(arameter: any) {
  return Request.axiosInstance.post('/web/trade-detail/export',arameter)
}

// 交易额汇总
export function sumAmount(arameter: any) {
  return Request.axiosInstance.post('/web/trade-detail/sum-amount',arameter)
}

// 本月/时间段、交易额汇总
export function sumMonthAmount(arameter: any) {
  return Request.axiosInstance.post('/web/trade-detail/sum-month-amount',arameter)
}

export default { tradeList, tradeExport, sumAmount, sumMonthAmount }