import { Request } from '@/http/request'

// 隐私号用户价格查询
export function interfaceQueryName(parameter: any){
  return Request.axiosInstance.post('/open/interface/query-name', parameter)
}
// 
export function getInterface(parameter: any){
  return Request.axiosInstance.post('/open/interface/get-interface', parameter)
}
//兜底价格查询
export function getInterfacePrice(parameter: any){
  return Request.axiosInstance.post('/open/interface-price/get-interface-price', parameter)
}
export default {getInterfacePrice,interfaceQueryName,getInterface }