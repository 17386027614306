import { Request } from '@/http/request'

// 隐私号用户价格查询
export function userPriceQuery(parameter: any){
  return Request.axiosInstance.post('/open/user-price/query', parameter)
}
//新版查询
export function userPriceQueryNew(parameter: any){
  return Request.axiosInstance.post('/open/user-price/list', parameter)
}
// 价格接口删除 
export function userPriceDelete(parameter: any){
  return Request.axiosInstance.post('/open/user-price/delete', parameter)
}
//用户价格查询
export function userPriceQueryplus(parameter: any) {
  return Request.axiosInstance.post('/open/user-price/query-plus', parameter)
}
//用户价格删除
export function userPriceDeleteplus(parameter: any) {
  return Request.axiosInstance.post('/open/user-price/delete-plus', parameter)
}
//线路价格添加
export function userPriceAdd(parameter: any) {
  return Request.axiosInstance.post('/web/user-app/add', parameter)
}
export default { userPriceQuery, userPriceDelete,userPriceQueryNew ,userPriceQueryplus,userPriceDeleteplus,userPriceAdd}